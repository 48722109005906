import turtles from "../assets/images/turtles.png";
import { Helmet } from "react-helmet";

function Piercing() {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Waikiki | Piercing</title>
      </Helmet>
      <div>
        <section id="piercing" className="coffee_area mt-60 mb-30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_title text-center pb-30">
                  <h4 className="title">Piercing</h4>
                  <img className="turtles" alt="turtles" src={turtles} />
                  <h4 className="fst-italic mt-4">
                    All prices include stainless steel jewelry. There is an
                    additional up charge of $20-$40 for titanium jewelry
                    depending on the piece
                  </h4>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3>PRICES</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $60
                      <br />
                      Conch: $80
                      <br />
                      Industrial: $100
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $60
                      <br />
                      Helix / Anti-Helix: $60
                      <br />
                      Snug: $60
                      <br />
                      Smiley: $80
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Eyebrow: $80
                      <br />
                      Tongue: $60
                      <br />
                      Septum: $100
                      <br />
                      Labret: $80
                      <br />
                      Lip: $80 ($100 for 2 / snakebites)
                      <br />
                      Medusa: $80
                      <br />
                      Monroe: $80
                      <br />
                      Ashley: $60
                      <br />
                      Nose: $60
                      <br />
                      Tooth Gems: $60 Gold: $150
                      <br />
                      Angel Bites: $200
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $60
                      <br />
                      Nipple: $60 / $100 for set
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Piercing;
